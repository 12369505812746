import type { ChainConfig } from '@/config';
import { useHistoricalCollators } from '@/hooks/api/useHistoricalCollators';
import {
  useUserAllSharesAuto,
  useUserAllSharesManual,
} from '@/hooks/polkadot/staking';
import { useMemo } from 'react';

export function useMyCollatorsAddresses(
  config: ChainConfig,
): string[] | undefined {
  const { data: collators } = useHistoricalCollators();

  const autoShares = useUserAllSharesAuto(collators, config);
  const manualShares = useUserAllSharesManual(collators, config);

  return useMemo(() => {
    return collators?.filter(
      (collator) => !!autoShares?.[collator] || !!manualShares?.[collator],
    );
  }, [collators, autoShares, manualShares]);
}
