import { theme } from '@/theme';
import {
  Card as MantineCard,
  type CardProps as MantineCardProps,
  rgba,
} from '@mantine/core';

export const COLOR = {
  teal: theme.colors?.tanssiTeal?.[9],
  orange: theme.other?.colors?.orange,
  yellow: theme.other?.colors?.yellow,
  green: theme.other?.colors?.symbioticGreen,
};

export interface CardProps extends MantineCardProps {
  variant?: keyof typeof COLOR;
  children?: React.ReactNode;
}

export function Card({ variant, children, ...others }: CardProps) {
  return (
    <MantineCard
      p={{ base: 'md', xs: 'lg' }}
      radius={16}
      {...others}
      style={{
        ...others.style,
        border: `1px solid ${rgba('var(--mantine-color-gray-8)', 0.6)}`,
        background: variant
          ? `linear-gradient(10deg, ${COLOR[variant]} -100%, var(--mantine-color-dark-7) 40%,var(--mantine-color-dark-7) 70%, ${COLOR[variant]} 200%)`
          : 'var(--mantine-color-dark-7)',
      }}
    >
      {children}
    </MantineCard>
  );
}
