import type { ChainConfig } from '@/config';
import {
  type CollatorsPoolsValue,
  useCollatorsSharesSupplyManual,
  useCollatorsTotalStakedManual,
  useDelegatorsTotalStake,
  useUserAllSharesManual,
} from '@/hooks/polkadot/staking';

export function useDelegatorsTotalStakeManual(
  collators: string[] | undefined,
  config: ChainConfig,
): CollatorsPoolsValue | undefined {
  const shares = useUserAllSharesManual(collators, config);
  const totalStake = useCollatorsTotalStakedManual(collators, config);
  const sharesSupply = useCollatorsSharesSupplyManual(collators, config);

  return useDelegatorsTotalStake({
    collators,
    shares,
    totalStake,
    sharesSupply,
  });
}
