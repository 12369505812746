import type { ChainConfig } from '@/config';
import { useConnectedAddressOrProxied } from '@/hooks/useConnectedAddressOrProxied';
import { transformPendingOperations } from '@/shared/jobs/pendingOperations/pendingOperations.utils';
import {
  type UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useApi } from '../../useApi';
import type { PendingOperation } from '../staking.interfaces';

export function useUserStakingPendingOperations(
  config: ChainConfig,
): UseQueryResult<PendingOperation[] | undefined> {
  const api = useApi(config.ws);
  const address = useConnectedAddressOrProxied();

  return useQuery({
    queryKey: ['pendingOperations', address],
    queryFn: async () => {
      const res =
        await api?.query.pooledStaking.pendingOperations.entries(address);

      return res ? transformPendingOperations(config, res) : undefined;
    },
    enabled: !!api && !!address,
  });
}

export function useInvalidatePendingOperationsQuery() {
  const address = useConnectedAddressOrProxied();
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['pendingOperations', address],
    });
  }, [address, queryClient]);
}
