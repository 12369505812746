import { TokenAmount } from '@/components/TokenAmount';
import { Dot } from '@/components/pages/Staking/Portfolio/Dot';
import { useConnectedAddressOrProxied } from '@/hooks/useConnectedAddressOrProxied';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { DonutChart } from '@mantine/charts';
import { Box, Center, Group, List, Text } from '@mantine/core';
import type { AssetAmount } from '@moonbeam-network/xcm-types';

export enum PortfolioBalance {
  Free = 'Free',
  ManualStake = 'Manual Stake',
  CompoundingStake = 'Compounding Stake',
  ManualRewards = 'Manual Rewards',
  CompoundingRewards = 'Compounding Rewards',
  Pending = 'Pending',
  TotalStaked = 'Staked',
  TotalRewards = 'Current Rewards',
}

interface Props {
  totalAssetAmount: AssetAmount | undefined;
  balances: {
    name: PortfolioBalance;
    value: AssetAmount | undefined;
    color: string;
  }[];
  chartSize?: number;
  chartThickness?: number;
}

export function Portfolio({
  balances,
  totalAssetAmount,
  chartSize = 220,
  chartThickness = 24,
}: Props) {
  const { xs, md } = useMediaQuery();
  const address = useConnectedAddressOrProxied();

  const chartData = balances
    .filter((item) => item.value?.amount)
    .map((item) => ({
      ...item,
      value: Number(item.value?.toDecimal(2)),
    }));

  return (
    <Group
      gap={'lg'}
      wrap={md ? 'nowrap' : 'wrap'}
      justify={xs ? 'start' : 'center'}
    >
      <Center pos={'relative'}>
        <DonutChart
          size={chartSize}
          strokeWidth={0}
          paddingAngle={0}
          thickness={chartThickness}
          withTooltip={!!chartData.length}
          tooltipDataSource={'segment'}
          tooltipProps={{
            wrapperStyle: { zIndex: 100 },
          }}
          data={
            chartData.length
              ? chartData
              : [{ name: 'No data', value: 1, color: 'dark.6' }]
          }
        />
        <TokenAmount
          pos={'absolute'}
          top={'50%'}
          ta={'center'}
          left={'50%'}
          style={{ transform: 'translate(-50%, -50%)', zIndex: 0 }}
          amount={totalAssetAmount}
          symbolColor={'gray.6'}
          size={'md'}
          lh={1}
        />
      </Center>

      <Box>
        <Group gap={5}>
          <Text c={'gray.6'}>Total:</Text>
          <TokenAmount
            amount={totalAssetAmount}
            symbolColor={'gray.6'}
            size={'md'}
          />
        </Group>

        <List>
          {balances.map((item) => {
            if (
              [
                PortfolioBalance.CompoundingRewards,
                PortfolioBalance.ManualRewards,
                PortfolioBalance.Pending,
              ].includes(item.name) &&
              !item.value?.amount
            )
              return;

            return (
              <List.Item
                key={item.name}
                icon={<Dot disabled={!address} color={item.color} />}
              >
                <Group gap={5}>
                  <Text c={'gray.6'}>
                    {item.name}
                    {':'}
                  </Text>
                  <TokenAmount
                    amount={item.value}
                    symbolColor={'gray.6'}
                    size={'md'}
                  />
                </Group>
              </List.Item>
            );
          })}
        </List>
      </Box>
    </Group>
  );
}
