import type { PolkadotChainConfig } from '@/config/config.interfaces';
import type {
  CollatorResponseMetadata,
  IdentityMetadata,
} from '@/shared/db/entities/collators';
import { createLogger } from '@/shared/logger';
import { areDefined, isDefined } from '@/utils/common';
import { getCollatorApy } from '@/utils/tanssi';
import { getPolkadotApi } from '@moonbeam-network/xcm-utils';
import type { ApiPromise } from '@polkadot/api';
import Big from 'big.js';
import pRetry from 'p-retry';
import type {
  AssignedCollators,
  CollatorsPoolsValue,
} from './collators.interfaces';
import {
  getActiveCollators,
  getActiveConfig,
  getAllCollatorsAddresses,
  getCollatorPools,
  getCollatorsPoolsWithOption,
  getCollatorsSelfSharesAuto,
  getIdentities,
  getInflationRewards,
  getUpcomingCollators,
} from './queries';

export const BLOCKS_PER_YEAR = 2628000;

const logger = createLogger('jobs-collators-utils');

export async function getCollatorsWithRetry(
  config: PolkadotChainConfig,
): Promise<{ collators: CollatorResponseMetadata[] } | undefined> {
  return pRetry(() => getCollators(config), {
    retries: 5,
    maxTimeout: 5_000,
    minTimeout: 5_000,
    onFailedAttempt: async (error) => {
      logger.error(
        error,
        `Fetching collators attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
      );
    },
  });
}

async function getCollators(config: PolkadotChainConfig) {
  const api: ApiPromise = await getPolkadotApi(config.ws);
  const collatorsAddresses = await getAllCollatorsAddresses(api);

  if (!collatorsAddresses) {
    return;
  }

  const [active, upcoming, identities, total, self, delegations, apy] =
    await Promise.all([
      getActiveCollatorsAddresses(api),
      getUpcomingCollatorsAddresses(api),
      getIdentities(collatorsAddresses, api),
      getCollatorsTotalStake(collatorsAddresses, api),
      getCollatorsSelfStake(collatorsAddresses, api),
      getCollatorsDelegations(collatorsAddresses, api),
      getCollatorsApy(collatorsAddresses, api),
    ]);

  const areAddressesAndStakesProvided = total && self && active && upcoming;

  if (!areAddressesAndStakesProvided) {
    return;
  }

  const collators = collatorsAddresses.map((address) =>
    mapCollatorAddresses({
      address,
      total,
      decimals: config.decimals,
      self,
      identities,
      active,
      upcoming,
      delegations,
      apy,
    }),
  );

  return { collators };
}

async function getActiveCollatorsAddresses(
  api: ApiPromise,
): Promise<string[] | undefined> {
  const activeCollators = await getActiveCollators(api);

  if (!activeCollators) {
    return;
  }

  return getAddressesFromCollators(activeCollators);
}

async function getUpcomingCollatorsAddresses(
  api: ApiPromise,
): Promise<string[] | undefined> {
  const [pending, active] = await Promise.all([
    getUpcomingCollators(api),
    getActiveCollators(api),
  ]);

  const isPendingEmpty =
    pending === null ||
    (pending?.orchestratorChain.length === 0 &&
      Object.keys(pending?.containerChains).length === 0);

  const collators = isPendingEmpty ? active : pending;

  if (!collators) {
    return;
  }

  return getAddressesFromCollators(collators);
}

function getAddressesFromCollators(collators: AssignedCollators): string[] {
  const addresses = [];

  for (const chainId in collators.containerChains) {
    addresses.push(...collators.containerChains[chainId]);
  }

  return [...collators.orchestratorChain, ...addresses];
}

async function getCollatorsTotalStake(
  collators: string[] | undefined,
  api: ApiPromise,
): Promise<CollatorsPoolsValue | undefined> {
  const [auto, manual] = await Promise.all([
    getCollatorsPoolsWithOption({
      collators: collators,
      api,
      option: 'AutoCompoundingSharesTotalStaked',
    }),
    getCollatorsPoolsWithOption({
      collators: collators,
      api,
      option: 'ManualRewardsSharesTotalStaked',
    }),
  ]);

  if (!auto || !manual || !collators) {
    return;
  }

  const result: CollatorsPoolsValue = {};

  collators.forEach((collator) => {
    const autoValue = auto[collator];
    const manualValue = manual[collator];

    result[collator] = (autoValue || 0n) + (manualValue || 0n);
  });

  return result;
}

async function getCollatorsSelfStake(
  allCollatorsAddresses: string[],
  api: ApiPromise,
): Promise<CollatorsPoolsValue | undefined> {
  const [totalStakeAuto, sharesSupplyAuto, selfSharesAuto] = await Promise.all([
    getCollatorsPoolsWithOption({
      collators: allCollatorsAddresses,
      api,
      option: 'AutoCompoundingSharesTotalStaked',
    }),
    getCollatorsPoolsWithOption({
      collators: allCollatorsAddresses,
      api,
      option: 'AutoCompoundingSharesSupply',
    }),
    getCollatorsSelfSharesAuto(allCollatorsAddresses, api),
  ]);

  if (
    !allCollatorsAddresses ||
    !totalStakeAuto ||
    !sharesSupplyAuto ||
    !selfSharesAuto
  ) {
    return;
  }

  const totalDelegationStake: CollatorsPoolsValue = {};

  allCollatorsAddresses.forEach((address) => {
    const supply = sharesSupplyAuto[address];
    const total = totalStakeAuto[address];
    const myShares = selfSharesAuto[address];

    if (!supply || !total || !myShares) {
      return;
    }

    totalDelegationStake[address] = (myShares * total) / supply;
  });

  return totalDelegationStake;
}

async function getCollatorsDelegations(
  allCollatorsAddresses: string[],
  api: ApiPromise,
): Promise<Record<string, number> | undefined> {
  const poolsQueryResult = await getCollatorPools(allCollatorsAddresses, api);

  if (!poolsQueryResult) {
    return;
  }

  const delegations: Record<string, number> = {};

  poolsQueryResult.forEach((pools) => {
    if (!isDefined(pools?.collator)) return;

    const addresses = new Set([pools.collator]);

    [
      ...(pools.AutoCompoundingShares || []),
      ...(pools.ManualRewardsShares || []),
    ].forEach((shares) => {
      const [address, amount] = Object.entries(shares)[0];

      if (amount > 0n) addresses.add(address);
    });

    delegations[pools.collator] = addresses.size - 1;
  });

  return delegations;
}

export async function getCollatorsApy(
  collators: string[] | undefined,
  api: ApiPromise,
  withStake?: Big | undefined,
): Promise<Record<string, number> | undefined> {
  const [
    blockRewardsPerChain,
    activeCollatorsRaw,
    collatorsTotal,
    tanssiConfig,
  ] = await Promise.all([
    getInflationRewards(api),
    getActiveCollators(api),
    getCollatorsTotalStake(collators, api),
    getActiveConfig(api),
  ]);

  if (
    !collators ||
    !activeCollatorsRaw ||
    !blockRewardsPerChain ||
    !tanssiConfig
  ) {
    return;
  }

  const appchains = Object.keys(activeCollatorsRaw.containerChains).length;
  const orchestrators = activeCollatorsRaw.orchestratorChain.length;
  const yearlyRewardsPerChain = Big(blockRewardsPerChain.toString()).mul(
    BLOCKS_PER_YEAR,
  );

  if (!yearlyRewardsPerChain) {
    return;
  }

  const yearlyRewardsPerCollator = getRewardsPerCollator(
    yearlyRewardsPerChain,
    appchains,
    tanssiConfig.collatorsPerContainer,
    orchestrators,
  );

  const collatorsApy: Record<string, number> = {};

  collators.forEach((collator) => {
    const total = collatorsTotal?.[collator] || 0n;
    const totalBig = Big((total || 1n).toString());
    const apy = getCollatorApy(yearlyRewardsPerCollator, totalBig, withStake);

    collatorsApy[collator] = Number(apy);
  });

  return collatorsApy;
}

function mapCollatorAddresses({
  address,
  total,
  decimals,
  self,
  identities,
  active,
  upcoming,
  delegations,
  apy,
}: {
  total: CollatorsPoolsValue;
  self: CollatorsPoolsValue;
  address: string;
  decimals: number;
  identities: Record<string, IdentityMetadata | undefined> | undefined;
  active: string[] | undefined;
  upcoming: string[] | undefined;
  delegations: Record<string, number> | undefined;
  apy: Record<string, number> | undefined;
}): CollatorResponseMetadata {
  const totalStringified = {
    amount: String(total[address] || 0n),
    decimals,
  };

  const selfStringified = {
    amount: String(self[address] || 0n),
    decimals,
  };

  const delegatedStringified = {
    amount: String(
      areDefined(total[address], self[address])
        ? total[address] - self[address]
        : 0n,
    ),
    decimals,
  };

  return {
    address,
    identity: identities?.[address],
    isActive: !!active?.includes(address),
    isUpcoming: !!upcoming?.includes(address),
    total: totalStringified,
    self: selfStringified,
    delegated: delegatedStringified,
    delegations: delegations?.[address] || 0,
    apy: apy?.[address] || 0,
  };
}

// [(rewards * appchains) + (rewards * orchestratorChain)] / (orchestrators + collators)
export function getRewardsPerCollator(
  yearlyRewardsPerChain: Big,
  appchains: number,
  collatorsPerAppchain: number,
  orchestrators: number,
): Big {
  const operators = Big(appchains)
    .mul(collatorsPerAppchain)
    .plus(orchestrators);

  if (operators.eq(0)) {
    return Big(0);
  }

  return yearlyRewardsPerChain
    .mul(appchains)
    .add(yearlyRewardsPerChain)
    .div(operators);
}
