import { useAddress } from '@/state/token';
import { trpc } from '@/utils/trpc';

export function useHistoricalCollators() {
  const address = useAddress();

  return trpc.staking.fetchHistoricalCollatorsList.useQuery(undefined, {
    enabled: !!address,
  });
}
