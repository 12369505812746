import type { ChainConfig } from '@/config';
import {
  type CollatorsPoolsValue,
  useCollatorsPoolsWithOption,
} from '@/hooks/polkadot/staking';
import { useConnectedAddressOrProxied } from '@/hooks/useConnectedAddressOrProxied';

export function useUserAllSharesManual(
  collators: string[] | undefined,
  config: ChainConfig,
): CollatorsPoolsValue | undefined {
  const address = useConnectedAddressOrProxied();

  return useCollatorsPoolsWithOption({
    option: { ManualRewardsShares: { delegator: address } },
    collators,
    config,
  });
}
